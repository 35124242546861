import styled from 'styled-components';

export const FileUploadPermissionModuleStyles = styled.footer`
  .defaultFilePicker {
    display: none;
  }

  .options {
    display: flex;
    
    &.row {
      justify-content: space-around;
    }

    &.column {
      flex-direction: column;
      min-height: 280px;
      justify-content: space-between;
    }
  }

  .checkboxFieldContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .actionType {
      margin-top: 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #2D2D2D;

      &.disabled {
        color: rgba(45, 45, 45, 0.3);
      }
    }
    
    .iconContainer {
      height: 20px;
    }
    
    button {
      width: 44px;
      height: 44px;
      border-radius: 22px;
      background-color: #f2f2f2;
      display: grid;
      place-items: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #D92A2A;
  }

  .answers {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    .fileItem {
      display: grid;
      grid-template-columns: 72px 1fr 32px;
      grid-gap: 16px;
      align-items: center;
      background-color: #f9f9f9;
      height: 48px;
      padding: 0 16px;
      border-radius: 4px;

      span {
        text-overflow: ellipsis;
        font-size: 13px;
        color: #00729f;
      }

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #000;
      }

      button {
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;

        svg {
          transform: rotate(45deg);
        }
      }
    }
  }
  .uploadFilesLimitTitle {
    width: 80%;
    margin-bottom: 30px;
    color: #D92A2A;
  }
`;
