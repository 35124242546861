import React, { useEffect } from 'react';
import { DocumentStyles } from './DocumentStyles';
import { SquareCheckbox } from '../squareCheckbox/SquareCheckbox';
import { useAppDispatch } from 'state/hooks';
import { setDocumentTermsAgreed } from 'state/slices/formSlice';
import { IDocumentProps } from './types';
import { proxyFile } from 'utils/proxyFile';
import { DevText } from 'components/dev';
import { useFormikContext } from 'formik';
import { IFormStateV2 } from 'pages/form/types';

export function Document({
  id,
  field,
  index,
  setTermsAgreed,
  handleChange,
  setFieldValue,
}: IDocumentProps) {
  const dispatch = useAppDispatch();
  const { errors } = useFormikContext<IFormStateV2>();
  function getValueFromOptions(key: string) {
    return field.options?.find(
      (option: { key: string; value: string }) => option.key === key,
    )?.value;
  }
  // @ts-ignore
  const error = errors?.extraFields?.[`${id}`]?.selected;
  const name = getValueFromOptions('file_description');
  const url = getValueFromOptions('file_url');
  const documentDescription = getValueFromOptions('description');
  // extraFields[${field.id}|||s].answers
  // useEffect(() => {
  //   if (setTermsAgreed && setFieldValue) {
  //     setFieldValue(`extraFields[${index}].selected`, setTermsAgreed);
  //     dispatch(
  //       setDocumentTermsAgreed({ selected: setTermsAgreed, id: field.id }),
  //     );
  //   }
  // }, [setTermsAgreed]);
  return (
    <DocumentStyles>
      <div className="documentsContainer">
        <DevText>{id}</DevText>
        <h3 className="documentDescription">{documentDescription}</h3>
        <SquareCheckbox
          key={field.id}
          showError
          name={`extraFields[${index}].selected`}
          value={field.selected!}
          onChange={(event) => {
            setFieldValue(
              `extraFields[${index}].selected`,
              event.target.checked,
            );

            // dispatch(
            //   setDocumentTermsAgreed({
            //     selected: event.target.checked,
            //     id: field.id,
            //   }),
            // );
          }}
        >
          {field.question}{' '}
          <a
            href={proxyFile(url, true)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </SquareCheckbox>
        <div className="formErrorContainer">
          {error && <span aria-live="polite">{error}</span>}
        </div>
      </div>
    </DocumentStyles>
  );
}
