import { FC, useState } from 'react';
import { TopSectionContainer } from './styles';
import {
  useFontStyles,
  useFormStyles,
  useGlobalStyles,
} from 'hooks/styles/useStyles';
import {
  useFormSettings,
  useNodeSettings,
  useSettings,
} from 'hooks/formSettings/useFormSettings';
import { useLanguage } from 'hooks/language/useLanguage';
import { proxyFile } from 'utils/proxyFile';

export const TopSection: FC<{ isCaptionPage?: boolean }> = ({
  isCaptionPage,
}) => {
  const { isPageByPageForm } = useFormSettings();
  const { node } = useSettings();
  const { logoSelected, logoLocation } = useGlobalStyles();
  const {
    title, nodeAddress, logo, name,
  } = useFormStyles();
  const { nodeCode, showNodeCode } = useNodeSettings();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const { availableLanguages: languages } = useLanguage();

  const isLogoShown = isCaptionPage && isPageByPageForm && logoSelected;

  const renderLogo = () => (
    <div className={`logoContainer ${logoLocation || 'left'}`}>
      <div className={`logo ${logoLocation || 'left'}`}>
        <img src={proxyFile(logoSelected ?? '')} alt="Логотип" />
        <div className="emptyBlock" />
      </div>
    </div>
  );

  const renderAddress = (address: string) => (
    <p className="companyAddress">{address}</p>
  );

  return (
    <TopSectionContainer fontFamily={fontFamily}>
      <div className="topSection">
        {isLogoShown && renderLogo()}
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={node.nodeAddress}
          className="titleContainer"
          role="region"
        >
          {isPageByPageForm
            && isCaptionPage
            && node.nodeAddress
            && renderAddress(node.nodeAddress)}
        </div>
      </div>
    </TopSectionContainer>
  );
};
