import { LanguageSelector } from '../languageSelector';
import { useEffect, useState } from 'react';
import { HeaderContainer } from './styles';
import {
  useFormSettings,
  useNodeSettings,
} from 'hooks/formSettings/useFormSettings';
import { useLanguage } from 'hooks/language/useLanguage';
import { useGlobalStyles } from 'hooks/styles/useStyles';
import { useInterfaceSettings } from 'hooks/interface/useInterfaceSettings';
import {
  decreaseFont,
  increaseFont,
  setGrayMode,
} from 'state/slices/interfaceSlice';
import { useDispatch } from 'react-redux';
import { ContrastIcon } from 'assets/icons/ContrastIcon';
import { InterfaceIncrease } from 'assets/icons/InterfaceIncrease';
import { InterfaceDecrease } from 'assets/icons/InterfaceDecrease';
import { fileApi } from 'api/fileApi/FileApi';
import { proxyFile } from 'utils/proxyFile';

export const Header = () => {
  const dispatch = useDispatch();
  const {
    grayMode, fontScale, fullWidth, activeTab,
  } = useInterfaceSettings();

  const changeContrast = () => {
    dispatch(setGrayMode(!grayMode));
  };

  const [isAccessebilityMenuOpened, setIsAccessebilityMenuOpened] = useState(false);
  const { dividingLine } = useGlobalStyles();
  const { name, logo } = useFormSettings();
  const { nodeCode, showNodeCode } = useNodeSettings();
  const { isPageByPageForm } = useFormSettings();
  const { availableLanguages: languages } = useLanguage();

  const showHeaderAdditionalCondition = (languages && languages?.length > 1) || showNodeCode;

  const isHeaderShown = isPageByPageForm || (!isPageByPageForm && showHeaderAdditionalCondition);

  const customizedDividingLineStyles = {
    width: `${dividingLine?.width_in_percent}%`,
    height: `${dividingLine?.height}px`,
    backgroundColor: dividingLine?.color,
  };

  return (
    <HeaderContainer isOpened={isAccessebilityMenuOpened}>
      <div className="header">
        <div className="headerInnerContainer">
          {isPageByPageForm && (
            <div className="logoImage">
              <img className="logo" src={proxyFile(logo)} alt="Логотип" />
            </div>
          )}
          <LanguageSelector />
        </div>
        {showNodeCode && nodeCode && (
          <div className="nodeNumber">
            <p className="title">{nodeCode}</p>
          </div>
        )}
        {isHeaderShown && dividingLine?.isShown && (
          <div className="dividingLine" style={customizedDividingLineStyles} />
        )}
        <div className="floatingMenu">
          {isAccessebilityMenuOpened && (
            <div className="items">
              <button
                type="button"
                className="user-interface"
                onClick={() => dispatch(increaseFont())}
                aria-label="Збільшення шрифта"
              >
                <InterfaceIncrease
                  color={fontScale >= 0.8 ? 'rgba(0, 0, 0, 0.4)' : '#000'}
                />
              </button>

              <button
                type="button"
                className="user-interface"
                onClick={() => dispatch(decreaseFont())}
                aria-label="Зменшення шрифта"
              >
                <InterfaceDecrease
                  color={fontScale <= 0.4 ? 'rgba(0, 0, 0, 0.4)' : '#000'}
                />
              </button>
            </div>
          )}
          <button
            onClick={() => {
              setIsAccessebilityMenuOpened((e) => !e);
              changeContrast();
            }}
            type="button"
            className="floatingBtn"
            aria-label="Налаштування доступності"
          >
            <ContrastIcon width={25} height={18} />
          </button>
        </div>
      </div>
    </HeaderContainer>
  );
};
