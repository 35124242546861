import styled from 'styled-components';

export const SocialNetworksModuleStyles = styled.footer<{disabled: boolean}>`
  .options {
    display: flex;
    justify-content: space-around;

    &.changeFlexDirection {
      flex-direction: column;
      justify-content: space-between;
      min-height: 148px;
    }

    .radioButtonInnerContainer {
      display: flex;
      align-items: center;

      .radioText {
        margin-left: 10px
      }
    }
  }

  .networkInputField {
    margin-top: 20px;
  }

  .botsDescriptionContainer {
    width: 92%;
    padding: 12px;
    background-color: rgba(239, 242, 24, 0.2);
    border-radius: 10px;
    border: 1px solid #dbbf1f;

    .botName {
      margin-bottom: 6px;
      font-weight: 700;
    }

    .botDescriptionText {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .checkboxFieldContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;

    .radioText {
      font-size: 12px;
    }

    button {
      width: 54px;
      height: 54px;
      border-radius: 32px;
      background-color: #F2F2F2;
      display: grid;
      place-items: center;
      cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

      &.active {
        background-color: #c8e9ff;
      }

      .socialItem, svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .socialItem, svg {
    width: 30px;
    height: 30px;
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #D92A2A;
  }
`;
