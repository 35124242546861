import { CheckboxFieldStyles } from './CheckboxFieldStyles';
import React, { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { questionTextByFormType } from 'utils/questionTextByFormType';
import { onlySpaces } from 'utils';
import { CheckboxFieldProps } from './types';
import { SimpleField } from '../simpleField/SimpleField';
import { SquareCheckbox } from '../squareCheckbox/SquareCheckbox';
import { useFormSettings } from 'hooks/formSettings/useFormSettings';
import { IFormStateV2 } from 'pages/form/types';

export function CheckboxField({
  id,
  index,
  values,
  answerPositionV2,
  name,
  optionsName,
  optionsError,
  extraBlockStyles,
  disabled,

  question,
  required,

  handleChangeOptionsState,
  handleChangeChoiceDescription,
  description,
}: CheckboxFieldProps) {
  const { errors, touched } = useFormikContext<IFormStateV2>();
  const { isPageByPageForm } = useFormSettings();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const data = values.extraFields[index];
  const fieldArrayName = `extraFields[${index}].answers`;

  function handleKeyUp(key: string) {
    setErrorMessage(
      typeof errorMessage === 'object'
        ? { ...errorMessage, [key]: undefined }
        : errorMessage,
    );
  }

  function getErrorMessage(key: string) {
    return typeof errorMessage === 'object'
      ? errorMessage[key]
          && `* ${errorMessage[key].charAt(0).toUpperCase()}${errorMessage[
            key
          ].substring(1)}`
      : undefined;
  }

  const error = errors.extraFields?.[`${id}|||s`]?.answers;

  return (
    <CheckboxFieldStyles style={extraBlockStyles}>
      <div
        id="question"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        aria-label={questionTextByFormType(
          isPageByPageForm,
          answerPositionV2!,
          question,
        )}
        className="titleContainer"
        role="region"
      >
        {/* {required && <span>*</span>} */}
        <h3>
          {questionTextByFormType(
            isPageByPageForm,
            answerPositionV2!,
            question,
          )}
        </h3>
      </div>

      {description && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <div tabIndex={0} aria-label={description} role="region" className="titleContainer">
          <h3>{description}</h3>
        </div>
      )}

      <FieldArray
        name={fieldArrayName}
        render={() => (
          <div className="options">
            {data?.answers?.map((field: any, subIndex: number) => {
              const value = data.answers[subIndex].selected;
              const name = `extraFields[${index}].answers[${subIndex}].selected`;
              const fieldValueText = data.answers[subIndex].text;
              const descriptionIndex = data.answerDescriptionFor?.indexOf(
                field.text,
              );
              const isAnswersWithCommentRequired = values?.extraFields[index]
                ?.answersWithCommentRequired
                ? values?.extraFields[index]?.answersWithCommentRequired
                : [];
              let leaveCommentAnswers: string;
              if (
                isAnswersWithCommentRequired
                && isAnswersWithCommentRequired.length > 0
              ) {
                leaveCommentAnswers = values?.extraFields[index]?.answersWithCommentRequired[
                  descriptionIndex
                ]?.leaveCommetRequired;
              } else {
                leaveCommentAnswers = 'false';
              }
              // @ts-ignore
              const inputStylesSpecial = leaveCommentAnswers === 'true'
                // @ts-ignore
                && values.extraFields[index].answerDescription[
                  descriptionIndex
                ] === '' // @ts-ignore
                && errors?.extraFields?.[0]?.answers // @ts-ignore
                && touched?.extraFields?.[0]
                ? '#f5cecb'
                : '#F9F9F9';
              const placeholderStylesSpecial = leaveCommentAnswers === 'true'
                // @ts-ignore
                && values.extraFields[index].answerDescription[
                  descriptionIndex
                ] === '' // @ts-ignore
                && errors?.extraFields?.[0]?.answers // @ts-ignore
                && touched?.extraFields?.[0]
                ? '#000000'
                : '';

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="checkboxFieldContainer" key={subIndex}>
                  <SquareCheckbox
                    index={subIndex + 1}
                    name={name}
                    value={value || ''}
                    onChange={handleChangeOptionsState}
                  >
                    {fieldValueText}
                  </SquareCheckbox>

                  {data.answers[subIndex].selected
                    && values?.extraFields[index]?.answerDescriptionFor?.includes(
                      field.text,
                    ) && (
                      <div className="choiceDescription">
                        {!onlySpaces(
                          values.extraFields[index]?.answerTitle?.[
                            descriptionIndex
                          ],
                        ) && (
                          <div className="answerTitleContainer">
                            <p>
                              {
                                values.extraFields[index]?.answerTitle?.[
                                  descriptionIndex
                                ]
                              }
                            </p>
                          </div>
                        )}
                        <SimpleField
                          id={id}
                          answerPositionV2={answerPositionV2 ?? 0}
                          // isPageByPageForm={isPageByPageForm}
                          index={index}
                          name={`extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`}
                          errorName={`extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`}
                          onChange={(key, value) => (values.extraFields[index].answerDescription
                            ? handleChangeChoiceDescription(
                              `extraFields[${index}].answerDescription[${descriptionIndex}]`,
                              value,
                            )
                            : null)}
                          onKeyUp={() => handleKeyUp(
                            `extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`,
                          )}
                          value={
                            values.extraFields[index].answerDescription[
                              descriptionIndex
                            ]
                          }
                          error={
                            typeof errorMessage === 'object'
                              ? getErrorMessage(
                                `extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`,
                              )
                              : undefined
                          }
                          placeholder={
                            values.extraFields[index].answerPlaceholder[
                              descriptionIndex
                            ]
                          }
                          inputType="text"
                          required={false}
                          inputStylesSpecial={inputStylesSpecial}
                          inputPlaceholderSpecial={placeholderStylesSpecial}
                        />
                      </div>
                  )}
                </div>
              );
            })}
            <div className="formErrorContainer">
              {/* {optionsError && <span>{optionsError}</span>}
              {!optionsError && touched && (
                <ErrorMessage name={optionsName} component={FormErrorMessage} />
              )} */}
              {error && (
                <p aria-live="polite" aria-label={`Помилка ${error}`}>
                  {error}
                </p>
              )}
            </div>
          </div>
        )}
      />
    </CheckboxFieldStyles>
  );
}
