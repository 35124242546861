import React from 'react';
import { ScaleFieldStyles } from './ScaleFieldStyles';
import {
  ColorScale, NumberScale, StarScale, NpsScale,
} from '../scales';
import { questionTextByFormType } from 'utils/questionTextByFormType';
import { TextComponent } from '../textComponent/TextComponent';
import { ScaleFieldProps } from './types';
import { useFieldStyles, useFontStyles } from 'hooks/styles/useStyles';
import {
  useFormSettings,
  useSettings,
} from 'hooks/formSettings/useFormSettings';
import { DevText } from 'components/dev';

const defaultMaxValues = [10, 5, 5];

export function ScaleField({
  id,
  index,
  answerPositionV2,
  value,
  // scaleType,
  signatureMin,
  signatureMax,
  // colors,
  maxValues,
  defaultValues,
  handleChange,
  required,
  errorName,
  question,
  extraBlockStyles,
  description,
  field,
}: ScaleFieldProps) {
  // const { touched, errors } = useFormikContext<IFormStateV2>();
  const selectedStyles = useFieldStyles().get(id!);
  const { companyID: companyId } = useSettings();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const { isPageByPageForm } = useFormSettings();
  const descriptionTextColor = field?.options?.find(
    // @ts-ignore
    (item) => item.key === 'description_text_color',
  )?.value;

  const scaleValues = field?.answers // @ts-ignore
    ?.map((item) => Number(item.text)) // @ts-ignore
    ?.sort((a, b) => a - b);
  const showDefaultValue = field?.showDefaultValue;

  const scaleType = field.options?.find((e) => e.key === 'scale_type')?.value;
  const defaultValue = field.options?.find(
    (e) => e.key === 'default_value',
  )?.value;
  return (
    <ScaleFieldStyles style={extraBlockStyles} fontFamily={fontFamily}>
      <DevText>{id}</DevText>
      <div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        id="question"
        aria-label={questionTextByFormType(
          isPageByPageForm,
          answerPositionV2!,
          question,
        )}
        className="titleContainer"
        role="region"
      >
        {/* {required && <span>*</span>} */}
        <h3>
          {questionTextByFormType(
            isPageByPageForm,
            answerPositionV2!,
            question,
          )}
        </h3>
      </div>
      {description && (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          id="question"
          aria-label={description}
          className="descriptionContainer"
        >
          <TextComponent
            font={fontFamily}
            fontSize="10"
            color={descriptionTextColor}
          >
            {description}
          </TextComponent>
        </div>
      )}

      {scaleType === 'color' && (
        <ColorScale
          id={id.toString()}
          // isPageByPageForm={isPageByPageForm}
          colors={[
            selectedStyles?.mainScaleColor1 ?? 'blue',
            selectedStyles?.mainScaleColor3
              ? selectedStyles?.mainScaleColor2 ?? 'red'
              : selectedStyles?.mainScaleColor1 ?? 'green',
            selectedStyles?.mainScaleColor3
              ?? selectedStyles?.mainScaleColor2
              ?? 'yellow',
            selectedStyles?.mainScaleColor4
              ?? selectedStyles?.mainScaleColor2
              ?? 'orange',
          ]}
          pointerColor={selectedStyles?.pointerColor ?? '#000'}
          signatureColor={selectedStyles?.signatureTextColor ?? 'grey'}
          maxValue={+maxValues || defaultMaxValues[0]}
          defaultValue={+defaultValue! || 0}
          signatures={[signatureMin || '', signatureMax || '']}
          errorName={errorName}
          // @ts-ignore
          value={[value]}
          handleChange={handleChange}
          scaleValues={scaleValues!}
        />
      )}
      {scaleType === 'scale' && (
        <NumberScale
          id={id}
          numbersColor={selectedStyles?.numbersColor ?? 'black'}
          selectedButtonColor={selectedStyles?.selectedButtonColor ?? 'black'}
          selectedNumberColor={selectedStyles?.selectedNumberColor ?? 'white'}
          signatureColor={selectedStyles?.signatureColor ?? 'rgb(4, 42, 88)'}
          defaultValue={+defaultValues || 1}
          signatures={[signatureMin || '', signatureMax || '']}
          errorName={errorName}
          // @ts-ignore
          value={value}
          handleChange={handleChange}
          scaleValues={scaleValues!}
          showDefaultValue={showDefaultValue}
        />
      )}
      {scaleType === 'ces' && (
        <StarScale
          id={id}
          selectedStarColor={selectedStyles?.selectedStarColor ?? '#FF8C19'}
          notSelectedStarBorder={
            selectedStyles?.notSelectedStarBorder ?? '#FF8C19'
          }
          notSelectedStarBackground={
            selectedStyles?.notSelectedStarBackground ?? '#fff'
          }
          signatureColor={selectedStyles?.signatureColor ?? 'rgb(4, 42, 88);'}
          maxValue={+maxValues || defaultMaxValues[2]}
          defaultValue={+defaultValues || 0}
          signatures={[signatureMin || '', signatureMax || '']}
          errorName={errorName}
          // @ts-ignore
          value={value}
          handleChange={handleChange}
          scaleValues={scaleValues!}
          showDefaultValue={showDefaultValue}
        />
      )}
      {(scaleType === 'nps' || scaleType === 'csat') && (
        <NpsScale
          id={id}
          signatureColor={selectedStyles?.signatureTextColor ?? ''}
          defaultValue={+defaultValues || 0}
          signatures={[
            selectedStyles?.signatureMin ?? '',
            selectedStyles?.signatureMax ?? '',
          ]}
          errorName={errorName}
          // @ts-ignore
          value={value}
          handleChange={handleChange}
          scaleValues={scaleValues!}
          showDefaultValue={showDefaultValue}
        />
      )}
    </ScaleFieldStyles>
  );
}
